















































































































import {
  Component, Vue, Ref, Prop, Watch,
} from 'vue-property-decorator';
import { H3Button, H3Checkbox } from '@authine/awesome-ui';
import { FlowService } from '@WorkPlatform/api';
import CreateEntrust from './create-entrust.vue';
import { CTable, CardInformation } from '@cloudpivot7-business-components/pc';
import { tableColumn } from '@WorkPlatform/types/type';

@Component({
  name: 'FlowEntrustComponent',
  components: {
    CTable,
    CreateEntrust,
    AButton: H3Button,
    ACheckbox: H3Checkbox,
    CardInformation,
  },
})
export default class FlowEntrustComponent extends Vue {
  @Ref() CTable!: any;

  @Prop({ default: false }) isFlowCenter!: boolean;

  isShowCreateFlowEntrust: boolean = false;

  operateType: number = 1; // 1: 新增 2：编辑

  editData: any = {};

  checkTableData: any[] = [];

  searchParams: any = {};

  isFilterExpired: boolean = false;

  // 表格数据
  tableList: any[] = [];

  // 表格分页配置
  paginationConfig: any = {
    current: 1,
    pageSize: 20,
    total: 0,
  };

  // 表格数据是否在加载
  loading: boolean = false;

  /**
   * table列定义
   */
  tableColumns: tableColumn[] = [
    {
      width: 60,
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      slots: { title: 'indexTitle', name: '序号' },
      scopedSlots: { customRender: 'index' },
    },
    {
      dataIndex: 'consignorName',
      key: 'consignorName',
      ellipsis: true,
      slots: { title: 'consignorNameTitle', name: '委托人' },
    },
    {
      dataIndex: 'mandataryName',
      key: 'mandataryName',
      ellipsis: true,
      slots: { title: 'mandataryNameTitle', name: '被委托人' },
      scopedSlots: { customRender: 'mandataryName' },
    },
    {
      dataIndex: 'entrustTime',
      key: 'entrustTime',
      ellipsis: true,
      slots: { title: 'entrustTimeTitle', name: '受托期限' },
      scopedSlots: { customRender: 'entrustTime' },
    },
    {
      width: 100,
      dataIndex: 'action',
      key: 'action',
      slots: { title: 'actionTitle', name: '操作' },
      scopedSlots: { customRender: 'action' },
    },
  ];

  created () {
    this.getList();
  }

  @Watch('isFlowCenter', { immediate: true })
  onFlowCenterChange () {
    if (this.isFlowCenter) {
      this.tableColumns.splice(1, 1);
    }
  }

  get isSuperAdmin () {
    const res: boolean = JSON.parse(localStorage.getItem('userInfo') || '{}').username === 'admin';
    return res;
  }

  get checkedTableList () {
    return !this.checkTableData.length;
  }

  showCreateEntrust () {
    this.isShowCreateFlowEntrust = true;
    this.operateType = 1;
  }

  /** 表格分页发生改变 */
  tableChange (params: {
    page: number;
    size: number;
  }) {
    this.paginationConfig.current = params.page;
    this.paginationConfig.pageSize = params.size;
    this.getList(params);
  }

  hasCheck (val) {
    if (val) {
      this.checkTableData = val;
    }
  }

  reload () {
    this.search();
  }

  /**
   * 搜索
   */
  search (searchParams: any = {}) {
    this.searchParams = searchParams;
    const initialPage: number = 1;
    this.paginationConfig.current = initialPage;

    this.getList();
  }

  filterExpired () {
    this.search();
  }

  /**
   * 获取列表数据接口
   */
  async getList (params?: any) {
    const p: any = {
      page: this.paginationConfig.current,
      size: this.paginationConfig.pageSize,
      ...params,
      ...this.searchParams,
      isFilterExpired: this.isFilterExpired,
    };

    if (this.isFlowCenter) {
      p.isCurrentUser = true;
    }
    this.loading = true;
    const res: any = await FlowService.getFlowEntrustList(p);
    if (res.success) {
      const data: any = res.data || [];

      data.forEach((item: any) => {
        item.entrustTime = `${item.entrustStartTime}~${item.entrustEndTime}`;
        let entrustTypeName: any = '';
        if (item.entrustType === 1) {
          entrustTypeName = '流程审批';
        } else if (item.entrustType === 2) {
          entrustTypeName = '流程发起';
        }
        item.entrustTypeName = entrustTypeName;
      });
    }

    this.tableList = res.data;
    this.paginationConfig.total = res.total || 0;
    this.loading = false;
  }

  editHandler (record: any) {
    this.editData = record;
    this.isShowCreateFlowEntrust = true;
    this.operateType = 2;
  }

  delHandler (record: any) {
    const okOk = () => {
      const params = [record.id];

      FlowService.delEntrust(params).then(() => {
        this.$message.success('删除成功！');
        this.reload();
      });
    };

    this.$confirm({
      title: '确定要删除这条流程委托吗？',
      content: '删除后，该数据将被全部删除且无法恢复，确认删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: okOk,
    });
  }

  delBath () {
    const okOk = () => {
      const params = this.checkTableData.map((item: any) => {
        return item.id;
      });

      FlowService.delEntrust(params).then(() => {
        this.$message.success('删除成功！');
        this.reload();
      });
    };

    this.$confirm({
      title: '确定要删除已选中信息吗？',
      content: '删除后，数据将被全部删除且无法恢复，确认删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: okOk,
    });
  }
}
