





























































































































































































































































































































import {
  Component, Vue, Ref, Watch,
} from 'vue-property-decorator';
import moment from 'moment';
import { Form, FormItem } from '@WorkPlatform/components/shared/CForm/index';
import {
  H3Select, H3Icon, H3Button, H3Tooltip, H3DatePicker, H3Input, H3Pagination,
} from '@authine/awesome-ui';
import SetField from './set-field.vue';

import { StaffSelector, CTable, CardInformation } from '@cloudpivot7-business-components/pc';
import { FlowCenterService } from '@WorkPlatform/api';
import agreeFlow from './comp/agree-flow.vue';
import agreeFlowFail from './comp/agree-flow-fail.vue';
import { getActionTextFromStorage, getFlowActionText, getImageUrl } from '@WorkPlatform/common/utils';
import { EClientType, FlowActions } from '@WorkPlatform/common/const';
import { pageForward } from '@WorkPlatform/service/flow-center';

@Component({
  name: 'FlowCenterComponent',
  components: {
    ASelect: H3Select,
    ASelectOption: H3Select.Option,
    AIcon: H3Icon,
    AButton: H3Button,
    ATooltip: H3Tooltip,
    AInput: H3Input,
    ARangePicker: H3DatePicker.RangePicker,
    CTable,
    CForm: Form,
    CFormItem: FormItem,
    StaffSelector,
    SetField,
    agreeFlow,
    agreeFlowFail,
    CardInformation,
    APagination: H3Pagination,
  },
})
export default class FlowCenterComponent extends Vue {
  @Ref() wrapperTable!: any;

  @Ref() cTable!: any;

  todoTypeOption: any[] = [
    { name: '待办', value: 1 },
    { name: '待阅', value: 2 },
    { name: '已办', value: 3 },
    { name: '已阅', value: 4 },
  ];

  flowStatus: any[] = [
    { label: '进行中', value: 1, enName: 'PROCESSING' },
    { label: '已完成', value: 2, enName: 'COMPLETED' },
    { label: '已作废', value: 3, enName: 'CANCELED' },
    { label: '流程异常', value: 4, enName: 'EXCEPTION' },
  ];

  form: any = {
    instanceName: '',
    moduleCode: undefined,
    originator: [],
    appCode: '', // 应用编码
    batchOperate: 0, // 是否支持批量处理
    publishTime: [],
    instanceState: undefined,
    handlerPerson: [],
  };

  // 待办应用列表
  appList: any = [];

  // 显示待办弹窗
  showAgreeFlow: boolean = false;

  // 批量审批部分失败
  showAgreeFlowFail: boolean = false;

  failInfo: any = {};

  flowTemplate: any[] = [];

  isExpand: boolean = false;

  isShowSetField: boolean = false;

  // 表格数据
  tableList: any[] = [];

  // 分页配置
  paginationConfig: any = {
    current: 1,
    pageSize: 20,
    total: 0,
  };

  // 数据正在加载
  loading: boolean = false;

  // 搜索表单是否具有隐藏输入框
  haveHiddenInput: boolean = false;

  /**
   * table列定义
   */
  columns: any[] = [
    {
      fixed: 'left',
      dataIndex: 'index',
      key: 'index',
      width: 80,
      align: 'center',
      disabledSetField: true,
      slots: { title: 'index', name: '序号' },
      scopedSlots: { customRender: 'index' },
    },
    {
      dataIndex: 'instanceName',
      key: 'instanceName',
      width: 250,
      slots: { title: 'instanceName', name: '流程名称' },
      scopedSlots: { customRender: 'instanceName' },
    },
    {
      dataIndex: 'workflowName',
      key: 'workflowName',
      width: 250,
      disabledSetField: true,
      ellipsis: true,
      slots: { title: 'workflowName', name: '流程模板' },
    },
    {
      dataIndex: 'activityName',
      key: 'activityName',
      ellipsis: true,
      width: 172,
      slots: { title: 'activityName', name: '当前节点' },
    },
    {
      dataIndex: 'participantName',
      key: 'participantName',
      ellipsis: true,
      width: 100,
      slots: { title: 'participantName', name: '处理人' },
    },
    {
      dataIndex: 'usedtime',
      key: 'usedtime',
      ellipsis: true,
      width: 172,
      slots: { title: 'usedtime', name: '停留时间' },
      scopedSlots: { customRender: 'usedtime' },
    },
    {
      dataIndex: 'instanceState',
      key: 'instanceState',
      ellipsis: true,
      width: 120,
      slots: { title: 'instanceState', name: '流程状态' },
      scopedSlots: { customRender: 'instanceState' },
    },
    {
      dataIndex: 'originatorName',
      key: 'originatorName',
      ellipsis: true,
      disabledSetField: true,
      width: 100,
      slots: { title: 'originatorName', name: '发起人' },
      scopedSlots: { customRender: 'originatorName' },
    },
    {
      dataIndex: 'instanceStartTime',
      key: 'instanceStartTime',
      ellipsis: true,
      width: 182,
      slots: { title: 'instanceStartTime', name: '发起时间' },
    },
    {
      dataIndex: 'departmentPathName',
      key: 'departmentPathName',
      ellipsis: true,
      disabledSetField: true,
      slots: { title: 'departmentPathName', name: '发起组织' },
      scopedSlots: { customRender: 'departmentPathName' },
    },
  ];

  hideFieldList: any[] = [];

  fieldColumns: any[] = [];

  defaultHideFieldList: any[] = ['participantName', 'baomidengji'];

  checkData: any[] = [];

  schemaCode: string = '';

  get wrapperTableHeight () {
    return this.wrapperTable.offsetHeight;
  }

  get tableColumns () {
    const columns: any = JSON.parse(JSON.stringify(this.columns));

    return this.todoType === 1 || this.todoType === 3
      ? columns
      : columns.filter((item: any) => item.key !== 'instanceState');
  }

  get isShowFlowStatausFormItem () {
    return this.todoType === 3;
  }

  get todoType () {
    return Number(this.$route.query.todoType) || 1;
  }

  get searchParams () {
    const form: any = this.form;

    // 发起人
    const originator: any = form.originator[0] || {};

    // 当前处理人
    // 发起时间
    const publishTime: any = form.publishTime || [];
    let startMomentTime: string = '';
    let endMomentTime: string = '';

    if (publishTime.length) {
      const startTime: any = publishTime[0] || {};
      const endTime: any = publishTime[1] || {};

      startMomentTime = moment(startTime).format('YYYY-MM-DD HH:mm:ss');
      endMomentTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss');
    }

    const params: any = {
      instanceName: form.instanceName,
      moduleCode: form.moduleCode,
      startTime: startMomentTime,
      endTime: endMomentTime,
      instanceState: form.instanceState,
      originator: originator.id,
      batchOperate: form.batchOperate,
      appCode: form.batchOperate ? form.appCode : '',
    };
    return params;
  }

  get urgeText () {
    return getActionTextFromStorage(FlowActions.Urge);
  }

  getImgUrl (img) {
    return getImageUrl(img);
  }

  @Watch('todoType')
  async onTodoTypeChange () {
    // 重置查询条件
    this.resetSearch();
    this.getFlowTemplate();
    this.setColumn();
    await this.search();
  }

  async created () {
    await getFlowActionText();
    this.getFlowTemplate();
    this.setColumn();
    window.addEventListener('message', this.reloadPage, false);
    window.addEventListener('resize', this.isOverHeight);
    this.getTodoAppList();
  }

  mounted () {
    this.isOverHeight();
  }

  switchSearch () {
    this.form.batchOperate = !this.form.batchOperate;
    this.reload();
  }

  @Watch('form.batchOperate', {
    immediate: true,
  })
  async onBatchOperateChange () {
    await this.reload();
    this.isOverHeight();
  }

  agreeFlow () {
    this.showAgreeFlow = true;
  }

  agreeAllFlow () {
    this.cTable.onChangeCheckAll({ target: { checked: true } });
    this.showAgreeFlow = true;
  }

  hasFail (failInfo) {
    this.showAgreeFlowFail = true;
    this.failInfo = failInfo;
  }

  changeTable () {
    if (!this.cTable) return;

    const selectionRow: any[] = this.cTable.getSelectionRow();

    const tempData: any = selectionRow.map(item => {
      return item.id;
    });
    this.schemaCode = selectionRow[0]?.schemaCode;
    this.checkData = [...tempData];
  }

  hasCheck (val) {
    if (val) {
      const tempData: any = val.map(item => {
        return item.id;
      });
      this.schemaCode = val[0]?.schemaCode;
      this.checkData = [...tempData];
    }
  }

  expandSearch () {
    this.isExpand = !this.isExpand;
    const wrapperTableHeight: number = this.wrapperTable.offsetHeight;
    // 待办批量处理
    if (this.todoType === 1 && this.form.batchOperate) {
      this.wrapperTable.style.height = `${this.isExpand ? wrapperTableHeight - 71 : wrapperTableHeight + 71
        }px`;
      this.cTable.setScrollHeight();
    }

    if (this.todoType !== 3) return;

    this.wrapperTable.style.height = `${this.isExpand ? wrapperTableHeight - 71 : wrapperTableHeight + 71
      }px`;
    this.cTable.setScrollHeight();
  }

  deepCopy (value: any) {
    return JSON.parse(JSON.stringify(value));
  }

  async showSetField () {
    this.isShowSetField = true;
  }

  async reload () {
    await this.getTodoAppList();
    await this.resetSearch();
    await this.setColumn();
    this.checkData = [];
    this.search();
  }

  onSearch () {
    this.search(this.searchParams);
  }

  resetSearch () {
    const { batchOperate, appCode } = this.form;
    this.form = {
      instanceName: '',
      moduleCode: undefined,
      originator: [],
      publishTime: [],
      instanceState: undefined,
      handlerPerson: [],
      batchOperate,
      appCode,
    };
  }

  /**
   * 获取流程模板
   */
  getFlowTemplate () {
    FlowCenterService.getFlowTemplate({ todoType: this.todoType }).then(
      (res: any) => {
        if (res.success) {
          this.flowTemplate = res.data || [];
        } else {
          this.flowTemplate = [];
        }
      },
    );
  }

  async updateFlowData () {
    this.checkData = [];
    await this.resetSearch();
    await this.onSearch();
  }

  async toggleMenu (todoType: any) {
    if (todoType === this.todoType) return;

    this.isExpand = false;
    this.wrapperTable.style.height = this.wrapperTableHeight + 'px';
    this.resetSearch();
    this.$router.push({
      name: 'flow-center',
      query: {
        todoType: todoType,
      },
    });
  }

  async getHideField () {
    this.hideFieldList = this.defaultHideFieldList;
  }

  async setColumn () {
    await this.getHideField();
    this.fieldColumns = [];

    for (const item of this.tableColumns) {
      if (!this.hideFieldList.includes(item.key)) {
        this.fieldColumns.push(item);
      }
    }
  }

  goPage (item: any) {
    pageForward(item, EClientType.PC);
  }

  /**
   * 分页发生变化
   * @param e 分页变化
   */
  onPageChange (e: { page: number; size: number }) {
    this.paginationConfig.current = e.page;
    this.paginationConfig.pageSize = e.size;
    const params = {
      ...this.searchParams,
      todoType: this.todoType,
      batchOperate: this.form.batchOperate,
      appCode: this.form.batchOperate ? this.form.appCode : '',
    };
    this.getList(params);
  }

  /**
   * 搜索
   */
  async search (searchParams: any = {}) {
    const initialPage: number = 1;
    this.paginationConfig.current = initialPage;
    const params = {
      ...searchParams,
      todoType: this.todoType,
      page: initialPage,
      batchOperate: this.form.batchOperate,
      appCode: this.form.batchOperate ? this.form.appCode : '',
      size: this.paginationConfig.pageSize,
    };

    this.getList(params);
  }

  /**
   * 获取列表数据接口
   */
  async getList (params?: any) {
    let appCode = '';
    if (this.todoType === 1) {
      appCode = this.form.appCode;
    }
    const p: any = {
      page: this.paginationConfig.current,
      size: this.paginationConfig.pageSize,
      ...params,
      todoType: this.todoType === 6 ? 1 : this.todoType,
      batchOperate: this.form.batchOperate,
      appCode: this.form.batchOperate ? appCode : '',
      ...this.searchParams,
    };
    this.loading = true;
    const res: any = await FlowCenterService.todoSearch(p);
    if (res.success) {
      const data: any = res.data || [];

      data.forEach((item: any) => {
        item.instanceStateText = (
          this.flowStatus.find((f: any) => f.enName === item.instanceState) ||
          {}
        ).label;
      });
    }

    this.tableList = res.data;
    this.paginationConfig.total = res.total || 0;
    this.loading = false;
  }

  timeOut (value: string) {
    const _value = value.replaceAll('-', '/');
    const oldTime: any = new Date(_value);
    const newTime: any = new Date();
    const timeout: any = parseInt((newTime - oldTime) + '', 10);
    return `已超时${this.formatSeconds(timeout)}`;
  }

  formatSeconds (value: any): string {
    if (!value) return '--';
    let theTime: number = parseInt(value, 10); // 需要转换的时间秒
    let theTime1: number = 0; // 分
    let theTime2: number = 0; // 小时
    let theTime3: number = 0; // 天

    theTime = theTime / 1000;

    if (theTime > 60) {
      theTime1 = parseInt(String(theTime / 60), 10);
      theTime = parseInt(String(theTime % 60), 10);
      if (theTime1 > 60) {
        theTime2 = parseInt(String(theTime1 / 60), 10);
        theTime1 = parseInt(String(theTime1 % 60), 10);
        if (theTime2 > 24) {
          // 大于24小时
          theTime3 = parseInt(String(theTime2 / 24), 10);
          theTime2 = parseInt(String(theTime2 % 24), 10);
        }
      }
    }
    let result: string = '';
    if (theTime > 0) {
      result = '' + parseInt(theTime + '', 10) + '秒';
    }
    if (theTime1 > 0) {
      result = '' + parseInt(theTime1 + '', 10) + '分' + result;
    }
    if (theTime2 > 0) {
      result = '' + parseInt(theTime2 + '', 10) + '小时' + result;
    }
    if (theTime3 > 0) {
      result = '' + parseInt(theTime3 + '', 10) + '天' + result;
    }

    return result;
  }

  beforeDestroy () {
    window.removeEventListener('message', this.reloadPage, false);
    window.removeEventListener('resize', this.isOverHeight);
  }

  /**
   * 如果流程发生变化，则刷新数据
   */
  reloadPage (evt: any) {
    if (evt.data === 'reload' && evt.origin === window.location.origin) {
      this.onSearch();
    }
  }

  handleClickRow (record) {
    this.goPage(record);
  }

  /**
   * 获取待办应用列表
   */
  async getTodoAppList () {
    const params: any = {
      batchOperate: true,
      onlineApp: false,
      todoType: 1,
    };

    const res: any = await FlowCenterService.getAppList(params);
    if (res.success) {
      this.appList = res.data || [];
      this.form.appCode = undefined;
    }
  }

  // 搜索表单是否有折叠的内容
  isOverHeight () {
    const target = document.getElementsByClassName('search-form')[0];
    if (
      target &&
      (target as HTMLElement).offsetHeight < (target as HTMLElement).scrollHeight
    ) {
      this.haveHiddenInput = true;
    } else {
      this.haveHiddenInput = false;
    }
  }

  get token () {
    return localStorage.getItem('token');
  }
}
