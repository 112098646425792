
















































































































































import {
  Component, Emit, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator';
import moment from 'moment';

import { StaffSelector } from '@cloudpivot7-business-components/pc';

import {
  H3Button, H3Radio, H3DatePicker, H3Drawer, H3TreeSelect, H3Icon, H3Tooltip,
} from '@authine/awesome-ui';

import { FlowService } from '@WorkPlatform/api';

import { Form, FormItem } from '@WorkPlatform/components/shared/CForm/index';
import TreeUtils from '@WorkPlatform/common/TreeUtils';

enum UnitType {
  Dept = 1,
  Role,
  User,
}

enum NodeType {
  Project = 'Project',
  App = 'App',
  Group = 'Group',
  Flow = 'Flow',
}

@Component({
  name: 'CreateEntrust',
  components: {
    CForm: Form,
    CFormItem: FormItem,
    StaffSelector,
    AButton: H3Button,
    ARadio: H3Radio,
    ARadioGroup: H3Radio.Group,
    ADatePicker: H3DatePicker,
    ADrawer: H3Drawer,
    ATreeSelect: H3TreeSelect,
    AIcon: H3Icon,
    ATooltip: H3Tooltip,
  },
})
export default class CreateEntrust extends Vue {
  @Prop() value!: boolean;

  @Prop() editData!: any;

  @Prop({ default: false }) isFlowCenter!: boolean;

  @Prop({ default: 1 }) operateType!: number;

  @Ref() cform: any;

  @Ref() treeSelect: any;

  @Emit('reload') reload () { }

  // 标题
  modalTitle: string = '新建委托';

  visible: boolean = false;

  pageLoading: boolean = false;

  confirmLoading: boolean = false;

  endOpen: boolean = false;

  userInfo: any = {};

  curUserOrgInfo: any = {};

  workflowRangeTreeData: any = [];

  staffOptions: any = {
    selectDept: false,
    multiple: false,
  };

  flowTypeList: any[] = [
    {
      value: 1,
      name: '流程审批',
    },
    {
      value: 2,
      name: '流程发起',
    },
  ];

  treeExpandedKeys: string[] = [];

  form: any = {
    principal: [],
    entrustedPerson: [],
    entrustType: undefined,
    rangeDate: [],
    formRangeDate: {
      startValue: null,
      endValue: null,
    },
    workflowRangeType: 1,
    workflowRange: [],
    workflowRangeId: [],
  };

  rules: any = {
    principal: [
      { required: true, message: '委托人不能为空', trigger: 'change' },
    ],
    entrustedPerson: [
      { required: true, message: '被委托人不能为空', trigger: 'change' },
    ],
    entrustType: [
      { required: true, message: '委托类型不能为空', trigger: 'change' },
    ],
    rangeDate: [
      { required: true, message: '受托期限不能为空', trigger: 'change' },
    ],
    workflowRangeType: [
      { required: true, message: '流程范围不能为空', trigger: 'change' },
    ],
    workflowRange: [
      { required: true, message: '部分流程不能为空', trigger: 'change' },
    ],
  };

  expired: boolean = false;

  setExpired () {
    if (this.operateType === 1) {
      this.expired = false;
    } else {
      this.expired = (this.editData && this.editData.expired) || false;
    }
  }

  // 必须要选择了委托人才可以选择流程范围
  get isSelectWorkflowRangeType () {
    return !!this.form.principal.length;
  }

  get excludeUserIds () {
    return this.form.principal.length && this.form.principal[0].id;
  }

  @Watch('form.formRangeDate', { deep: true, immediate: true })
  onFormRangeDateChange (date: any) {
    this.form.rangeDate = [];
    for (const value of Object.values(date)) {
      if (value) {
        this.form.rangeDate.push(value);
      }
    }
  }

  @Watch('form.workflowRangeType', { immediate: true })
  async onChangeRangeType (v: number) {
    if (v === 2 && this.form.principal.length) {
      if (!this.workflowRangeTreeData.length) {
        this.workflowRangeTreeData = await this.getTree(NodeType.App);
        this.readonlyTreeSelect();
      }
      this.editWorkflowRange();
    }
  }

  treeExpand (expandedKeys: string[] = []) {
    this.treeExpandedKeys = expandedKeys;
  }

  /**
   * 提示
   */
  showWorkflowRangeTypeTip () {
    if (!this.isSelectWorkflowRangeType) {
      this.$message.info('请先选择委托人');
    }
  }

  onChangePrincipal (principal: any[] = []) {
    if (!principal.length) {
      this.form.workflowRangeType = 1;
      this.form.workflowRange = [];
      this.form.workflowRangeId = [];
      this.form.entrustedPerson = [];
    }

    this.workflowRangeTreeData = [];
    this.treeExpandedKeys = [];
  }

  /**
   * 设置组件只能读，不能搜索
   */
  readonlyTreeSelect () {
    this.$nextTick(() => {
      if (!this.treeSelect) return;

      const input: any = this.treeSelect.$el.querySelector(
        '.ant-select-search__field',
      );

      if (!input) return;

      input.setAttribute('readonly', '');
    });
  }

  disabledStartDate (startValue: any) {
    const prevDate: any = moment().subtract(1, 'days');

    if (startValue.valueOf() < prevDate.valueOf()) {
      return true;
    }

    const endValue: any = this.form.formRangeDate.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  }

  disabledEndDate (endValue: any) {
    const prevDate: any = moment().subtract(1, 'days');

    if (endValue.valueOf() < prevDate.valueOf()) {
      return true;
    }

    const startValue: any = this.form.formRangeDate.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return startValue.valueOf() >= endValue.valueOf();
  }

  handleStartOpenChange (open) {
    if (!open) {
      if (!this.form.formRangeDate.endValue) {
        this.form.formRangeDate.endValue = moment(this.form.formRangeDate.startValue).add(1, 's');
      }
      this.endOpen = true;
      this.expired = false;
    }
  }

  handleEndOpenChange (open) {
    this.endOpen = open;
    if (!open) {
      this.expired = false;
    }
  }

  async editWorkflowRange () {
    this.readonlyTreeSelect();

    this.workflowRangeTreeData.forEach(async node => {
      if (node.nodeType === NodeType.App) {
        node.children = await this.getTree(NodeType.Flow, node.code, node.key);
      }
    });
    this.form.workflowRangeId = [];
    this.form.workflowRange.forEach(item => {
      this.form.workflowRangeId.push([item.appCode, item.workflowCode].join('-'));
    });
  }

  addWorkflowToRange () {
    this.form.workflowRange = [];
    for (const item of this.form.workflowRangeId) {
      const id: string = item.split('-')[1];
      const nodeState: any = TreeUtils.getTreeNodeStateByKey(
        item,
        'key',
        this.workflowRangeTreeData,
      );
      const node: any = nodeState.nodeState;
      const workflow: any = {};

      if (node) {
        const codeArr: string[] = node.key.split('-');
        workflow.appCode = codeArr[0];
        workflow.workflowCode = codeArr[1];
        workflow.workflowName = node.title;
      }

      this.form.workflowRange.push(workflow);
    }
    this.$refs.workflowRange && (this.$refs.workflowRange as any).onFieldChange();
  }

  async onLoadTreeData (e: any) {
    const node: any = e.dataRef;
    let nextNodeType: string = '';

    if (e.expanded || node.children.length) return;

    if (node.nodeType === NodeType.App) {
      nextNodeType = NodeType.Flow;
    }
    node.children = await this.getTree(nextNodeType, node.code, node.key);
  }

  async getTree (type: string, code?: string, parentKey?: string) {
    let list: any[] = [];
    let dataList: any[] = [];

    if (type === NodeType.App) {
      const principal: any = this.form.principal[0] || {};
      const userId: string = principal.id;
      dataList = await this.getAppList({ userId: userId });
    } else if (type === NodeType.Flow) {
      dataList = await this.getFlowList({ appCode: code });
    }

    list = this.generateTree(type, dataList, parentKey);

    return list;
  }

  generateTree (type, dataList: any[] = [], parentKey: string = '') {
    const list: any[] = [];

    dataList.forEach((item: any) => {
      let code: string = '';
      let title: string = '';

      const keys: string[] = [];

      if (parentKey) {
        keys.push(parentKey);
      }

      if (type === NodeType.App) {
        code = item.appCode;
        title = item.appName;
      }
      if (type === NodeType.Flow) {
        code = item.workflowCode;
        title = item.workflowName;
      }

      keys.push(code);

      list.push({
        key: keys.join('-'),
        title: title,
        code: code,
        children: [],
        nodeType: type,
        value: keys.join('-'),
        isLeaf: type === NodeType.Flow,
        disabled: type !== NodeType.Flow,
      });
    });

    return list;
  }

  async getAppList (params: any) {
    const res: any = await FlowService.getAppList(params);

    if (res.success) {
      return res.data;
    }

    return [];
  }

  async getGroupList (params: any) {
    const res: any = await FlowService.getGroupList(params);

    if (res.success) {
      return res.data;
    }

    return [];
  }

  async getFlowList (params: any) {
    const res: any = await FlowService.getFlowList(params);

    if (res.success) {
      return res.data;
    }

    return [];
  }

  getRangeDate (date: any) {
    let beginMomentTime: string = '';
    let endMomentTime: string = '';

    if (date.length) {
      const beginTime: any = date[0] || {};
      const endTime: any = date[1] || {};

      beginMomentTime = moment(beginTime).format('YYYY-MM-DD HH:mm:ss');
      endMomentTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss');

      return {
        beginMomentTime,
        endMomentTime,
      };
    }
  }

  setRangeDate (date: any) {
    const beginMomentTime: any = moment(
      date.entrustStartTime,
      'YYYY-MM-DD HH:mm:ss',
    );
    const endMomentTime: any = moment(
      date.entrustEndTime,
      'YYYY-MM-DD HH:mm:ss',
    );
    return [beginMomentTime, endMomentTime];
  }

  onClose () {
    this.$emit('input', false);
  }

  async save () {
    this.addWorkflowToRange();

    if (this.operateType === 1 && this.form.workflowRangeType === 1) {
      this.form.workflowRange = [];
    }

    const isValid: boolean = this.cform.validate();

    if (!isValid) return;

    if (this.form.rangeDate.length < 2) {
      this.$message.success('请选择开始日期/结束日期');
      return;
    }

    const startValue: any = this.form.formRangeDate.startValue;
    const endValue: any = this.form.formRangeDate.endValue;
    if (startValue.valueOf() >= endValue.valueOf()) {
      this.$message.error('请设置正确的开始日期/结束日期');
      return;
    }

    this.confirmLoading = true;
    if (this.operateType === 1) {
      await this.addEntrust();
    } else {
      await this.updateEntrust();
    }
  }

  forEachTree (workflowRangeTreeData: any[], callback: any) {
    const forEachTree = (tree: any[] = []) => {
      tree.forEach((item: any) => {
        callback(item);

        if (item.children && item.children.length) {
          forEachTree(item.children);
        }
      });
    };

    forEachTree(workflowRangeTreeData);
  }

  getWorkFlowName (id: string) {
    let name: string = '';
    this.forEachTree(this.workflowRangeTreeData, (item: any) => {
      if (item.code === id) {
        name = item.title;
      }
    });

    return name;
  }

  getWorkflowRange () {
    const workflowRange: any =
      this.form.workflowRangeType === 1 ? [] : this.form.workflowRange;

    return workflowRange;
  }

  getParams () {
    const date: any = this.getRangeDate(this.form.rangeDate);

    const params: any = {
      consignorId: this.form.principal[0].id,
      consignorName: this.form.principal[0].name,
      entrustEndTimeStr: date.endMomentTime,
      entrustStartTimeStr: date.beginMomentTime,
      entrustType: this.form.entrustType,
      mandataryId: this.form.entrustedPerson[0].id,
      mandataryName: this.form.entrustedPerson[0].name,
      workflowRangeType: this.form.workflowRangeType,
      workflowRange: this.getWorkflowRange(),
    };

    return params;
  }

  async addEntrust () {
    const params: any = this.getParams();

    FlowService.addEntrust(params)
      .then((res: any) => {
        if (res.success) {
          this.$message.success('添加成功');
          this.onClose();
          this.reload();
        }
      })
      .finally(() => {
        this.confirmLoading = false;
      });
  }

  async updateEntrust () {
    const params: any = this.getParams();
    params.id = this.editData.id;

    FlowService.updateEntrust(params)
      .then((res: any) => {
        if (res.success) {
          this.$message.success('更新成功');
          this.onClose();
          this.reload();
        }
      })
      .finally(() => {
        this.confirmLoading = false;
      });
  }

  resetForm () {
    this.form.principal = [];
    this.form.entrustedPerson = [];
    this.form.entrustType = undefined;
    this.form.rangeDate = [];
    this.form.formRangeDate.startValue = null;
    this.form.formRangeDate.endValue = null;
    this.form.workflowRangeType = 1;
    this.form.workflowRangeId = [];
    this.form.workflowRange = [];
    this.treeExpandedKeys = [];
    this.workflowRangeTreeData = [];
  }

  removeItem (item: any) {
    const workflowRangeIndex: number = this.form.workflowRange.findIndex(
      (workflowRangeItem: any) => {
        return workflowRangeItem.workflowCode === item.workflowCode;
      },
    );

    this.form.workflowRange.splice(workflowRangeIndex, 1);
  }

  @Watch('value')
  async onValueChange (v: boolean) {
    this.visible = v;

    this.setExpired();

    if (v) {
      this.resetForm();

      if (this.isFlowCenter) {
        const userInfo: any = JSON.parse(localStorage.getItem('user') || '{}')?.userInfo || {};
        this.form.principal = [
          { id: userInfo.id, name: userInfo.name, type: 3 },
        ];
      }

      this.form.entrustType = 1;

      if (this.operateType === 2) {
        this.modalTitle = '编辑委托';
        this.pageLoading = true;
        this.pageLoading = false;

        const editData: any = this.editData;

        this.form.principal = [
          { id: editData.consignorId, name: editData.consignorName, type: UnitType.User },
        ];
        this.form.entrustedPerson = [
          { id: editData.mandataryId, name: editData.mandataryName, type: UnitType.User },
        ];
        this.form.entrustType = editData.entrustType;

        const date: any = this.setRangeDate({
          entrustStartTime: editData.entrustStartTime,
          entrustEndTime: editData.entrustEndTime,
        });
        this.form.rangeDate = date;
        this.form.formRangeDate = {
          startValue: date[0],
          endValue: date[1],
        };

        this.form.workflowRangeType = editData.workflowRangeType;
        this.onChangeRangeType(this.form.workflowRangeType);
        this.form.workflowRangeId = [];
        this.form.workflowRange = editData.workflowRange || [];
      } else {
        this.modalTitle = '新建委托';
      }
    }
  }
}
