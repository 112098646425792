

























































































































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';
import { Form, FormItem } from '@WorkPlatform/components/shared/CForm/index';

import {
  H3Select, H3Icon, H3Button, H3Tooltip, H3DatePicker, H3Input, H3Tabs,
} from '@authine/awesome-ui';

import { CTable, CardInformation } from '@cloudpivot7-business-components/pc';
import { FlowCenterService } from '@WorkPlatform/api';
import {
  deepClone, getActionTextFromStorage, getFlowActionText, getImageUrl,
} from '@WorkPlatform/common/utils';
import {
  EClientType, EFlowStatusType, FlowActions, FlowStatusArray,
} from '@WorkPlatform/common/const';
import { pageForward } from '@WorkPlatform/service/flow-center';

@Component({
  name: 'FlowInitiateComponent',
  components: {
    ATabs: H3Tabs,
    ATabPane: H3Tabs.TabPane,
    ASelect: H3Select,
    ASelectOption: H3Select.Option,
    AIcon: H3Icon,
    AButton: H3Button,
    ATooltip: H3Tooltip,
    AInput: H3Input,
    ARangePicker: H3DatePicker.RangePicker,
    CTable,
    CForm: Form,
    CFormItem: FormItem,
    // StaffSelector,
    CardInformation,
  },
})
export default class FlowInitiateComponent extends Vue {
  @Ref() wrapperTable!: any;

  @Ref() cTable!: any;

  flowStatus: any[] = [
    { label: '进行中', value: 1, enName: 'PROCESSING' },
    { label: '已完成', value: 2, enName: 'COMPLETED' },
    { label: '已作废', value: 3, enName: 'CANCELED' },
    { label: '流程异常', value: 4, enName: 'EXCEPTION' },
  ];

  form: any = {
    instanceName: '',
    moduleCode: undefined,
    originator: [],
    appCode: '', // 应用编码
    batchOperate: false, // 是否支持批量处理
    publishTime: [],
    state: undefined,
    handlerPerson: [],
  };

  failInfo: any = {};

  flowTemplate: any[] = [];

  isExpand: boolean = false;

  mountTable = false;

  // 表格数据
  tableList: any[] = [];

  // 表格分页配置
  paginationConfig: any = {
    current: 1,
    pageSize: 20,
    total: 0,
  };

  // 表格是否正在加载
  loading: boolean = false;

  /**
   * table列定义
   */
  columns: any[] = [
    {
      fixed: 'left',
      dataIndex: 'index',
      key: 'index',
      width: 80,
      align: 'center',
      disabledSetField: true,
      slots: { title: 'index', name: '序号' },
      scopedSlots: { customRender: 'index' },
    },
    {
      dataIndex: 'instanceName',
      key: 'instanceName',
      width: 250,
      slots: { title: 'instanceName', name: '流程名称' },
      scopedSlots: { customRender: 'instanceName' },
    },
    {
      dataIndex: 'workflowName',
      key: 'workflowName',
      width: 250,
      disabledSetField: true,
      ellipsis: true,
      slots: { title: 'workflowName', name: '流程模板' },
    },
    {
      dataIndex: 'activityName',
      key: 'activityName',
      ellipsis: true,
      width: 172,
      slots: { title: 'activityName', name: '当前节点' },
    },
    {
      dataIndex: 'participantNames',
      key: 'participantNames',
      ellipsis: true,
      width: 100,
      slots: { title: 'participantNames', name: '处理人' },
    },
    {
      dataIndex: 'usedTime',
      key: 'usedTime',
      ellipsis: true,
      width: 172,
      slots: { title: 'usedTime', name: '停留时间' },
      scopedSlots: { customRender: 'usedTime' },
    },
    {
      dataIndex: 'state',
      key: 'state',
      ellipsis: true,
      width: 120,
      slots: { title: 'state', name: '流程状态' },
      scopedSlots: { customRender: 'state' },
    },
    {
      dataIndex: 'originatorName',
      key: 'originatorName',
      ellipsis: true,
      disabledSetField: true,
      width: 100,
      slots: { title: 'originatorName', name: '发起人' },
      scopedSlots: { customRender: 'originatorName' },
    },
    {
      dataIndex: 'createdTime',
      key: 'createdTime',
      ellipsis: true,
      width: 182,
      slots: { title: 'createdTime', name: '发起时间' },
    },
    {
      dataIndex: 'departmentPathName',
      key: 'departmentPathName',
      ellipsis: true,
      disabledSetField: true,
      slots: { title: 'departmentPathName', name: '发起组织' },
      scopedSlots: { customRender: 'departmentPathName' },
    },
  ];

  hideFieldList: any[] = [];

  fieldColumns: any[] = [];

  defaultHideFieldList: any[] = ['participantNames', 'baomidengji'];

  checkData: any[] = [];

  get wrapperTableHeight () {
    return this.wrapperTable.offsetHeight;
  }

  get tableColumns () {
    return JSON.parse(JSON.stringify(this.columns));
  }

  get IsDingTalk () {
    return sessionStorage.IS_DINGTALK === 'true';
  }

  get isFreeLoginPlatform () {
    return sessionStorage.IS_DINGTALK === 'true' || sessionStorage.IS_WECHAT === 'true';
  }

  get ISWECHAT () {
    return sessionStorage.IS_WECHAT === 'true';
  }

  get urgeText () {
    return getActionTextFromStorage(FlowActions.Urge);
  }

  getImgUrl (img) {
    return getImageUrl(img);
  }

  async created () {
    await getFlowActionText();
    this.getFlowTemplate();
    this.setColumn();
    window.addEventListener('message', this.reloadPage, false);
    this.getList();
    this.init();
  }

  beforeDestroy () {
    window.removeEventListener('message', this.reloadPage, false);
  }

  mounted () {

  }

  switchSearch () {
    this.form.batchOperate = !this.form.batchOperate;
    this.reload();
  }

  /** 表格分页发生变化 */
  tableChange (params: {
    page: number;
    size: number;
  }) {
    this.paginationConfig.current = params.page;
    this.paginationConfig.pageSize = params.size;
    const p = this.parseSearchParams();
    this.getList({ params, ...p });
  }

  changeTable () {
    if (!this.cTable) return;

    const selectionRow: any[] = this.cTable.getSelectionRow();

    const tempData: any = selectionRow.map(item => {
      return item.id;
    });
    this.checkData = [...tempData];
  }

  hasCheck (val) {
    if (val) {
      const tempData: any = val.map(item => {
        return item.id;
      });
      this.checkData = [...tempData];
    }
  }

  expandSearch () {
    this.isExpand = !this.isExpand;
    const wrapperTableHeight: number = this.wrapperTable.offsetHeight;
    // 待办批量处理
    if (this.todoType === 1 && this.form.batchOperate) {
      this.wrapperTable.style.height = `${this.isExpand ? wrapperTableHeight - 71 : wrapperTableHeight + 71
        }px`;
      this.cTable.setScrollHeight();
    }

    if (this.todoType !== 3) return;

    this.wrapperTable.style.height = `${this.isExpand ? wrapperTableHeight - 71 : wrapperTableHeight + 71
      }px`;
    this.cTable.setScrollHeight();
  }

  async reload () {
    await this.resetSearch();
    await this.setColumn();
    this.checkData = [];
    this.search();
  }

  onSearch () {
    const params = this.parseSearchParams();
    this.search(params);
  }

  /**
   * 解析搜索参数
   */
  parseSearchParams () {
    const form: any = this.form;

    // 当前处理人
    // 发起时间
    const publishTime: any = form.publishTime || [];
    let startMomentTime: string = '';
    let endMomentTime: string = '';

    if (publishTime.length) {
      const startTime: any = publishTime[0] || {};
      const endTime: any = publishTime[1] || {};

      startMomentTime = moment(startTime).format('YYYY-MM-DD HH:mm:ss');
      endMomentTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss');
    }

    const params: any = {
      instanceName: form.instanceName,
      moduleCode: form.moduleCode,
      startTime: startMomentTime,
      endTime: endMomentTime,
    };
    return params;
  }

  resetSearch () {
    const { batchOperate } = this.form;
    this.form = {
      instanceName: '',
      moduleCode: undefined,
      originator: [],
      publishTime: [],
      state: undefined,
      handlerPerson: [],
      batchOperate,
    };
  }

  onResetSearch () {
    this.resetSearch();
    this.onSearch();
  }

  /**
   * 获取流程模板
   */
  getFlowTemplate () {
    FlowCenterService.getFlowTemplate({ todoType: 0 }).then(
      (res: any) => {
        if (res.success) {
          this.flowTemplate = res.data || [];
        } else {
          this.flowTemplate = [];
        }
      },
    );
  }

  async updateFlowData () {
    this.checkData = [];
    await this.resetSearch();
    await this.onSearch();
  }

  async toggleMenu (todoType: any) {
    if (todoType === this.todoType) return;

    this.isExpand = false;
    this.wrapperTable.style.height = this.wrapperTableHeight + 'px';
    this.resetSearch();
    this.$router.push({
      name: 'flow-center',
      query: {
        todoType: todoType,
      },
    });
  }

  async getHideField () {
    this.hideFieldList = [];
  }

  async setColumn () {
    await this.getHideField();
    this.fieldColumns = [];

    for (const item of this.tableColumns) {
      if (!this.hideFieldList.includes(item.key)) {
        this.fieldColumns.push(item);
      }
    }
  }

  goPage (item: any) {
    // 我发起的流程无任务id，仅有流程实例id
    const record = Object.assign({}, item);
    record.instanceId = item.id;
    record.id = '';
    pageForward(record, EClientType.PC);
  }

  todoType = 6;

  /**
   * 搜索
   */
  async search (searchParams: any = {}) {
    const initialPage: number = 1;
    this.paginationConfig.current = initialPage;
    const params = {
      ...searchParams,
      page: initialPage,
      size: this.paginationConfig.pageSize,
    };

    this.getList(params);
  }

  /**
   * 获取列表数据接口
   */
  async getList (params?: any) {
    const p: any = {
      page: this.paginationConfig.current,
      size: this.paginationConfig.pageSize,
      state: this.flowStatusType,
      ...params,
    };
    this.loading = true;
    const res: any = await FlowCenterService.myInitiateWorkflow(p);
    if (res.success) {
      const data: any = res.data || [];

      data.forEach((item: any) => {
        item.instanceStateText = (
          this.flowStatus.find((f: any) => f.enName === item.state) ||
          {}
        ).label;
      });
    }

    this.tableList = res.data;
    this.paginationConfig.total = res.total || 0;
    this.loading = false;
  }

  timeOut (value: string) {
    const oldTime: any = new Date(value);
    const newTime: any = new Date();
    const timeout: any = parseInt((newTime - oldTime) + '', 10);
    return `已超时${this.formatSeconds(timeout)}`;
  }

  formatSeconds (value: any): string {
    if (!value) return '--';
    const unit: number = 10;
    const aSecond: number = 1000;
    const aMinute: number = 60;
    const aDay: number = 24;
    const empty: number = 0;
    let theTime: number = parseInt(value, 10); // 需要转换的时间秒
    let theTime1: number = 0; // 分
    let theTime2: number = 0; // 小时
    let theTime3: number = 0; // 天

    theTime = theTime / aSecond;

    if (theTime > aMinute) {
      theTime1 = parseInt(String(theTime / aMinute), unit);
      theTime = parseInt(String(theTime % aMinute), unit);
      if (theTime1 > aMinute) {
        theTime2 = parseInt(String(theTime1 / aMinute), unit);
        theTime1 = parseInt(String(theTime1 % aMinute), unit);
        if (theTime2 > aDay) {
          // 大于24小时
          theTime3 = parseInt(String(theTime2 / aDay), unit);
          theTime2 = parseInt(String(theTime2 % aDay), unit);
        }
      }
    }
    let result: string = '';
    if (theTime > empty) {
      result = '' + parseInt(theTime + '', unit) + '秒';
    }
    if (theTime1 > empty) {
      result = '' + parseInt(theTime1 + '', unit) + '分' + result;
    }
    if (theTime2 > empty) {
      result = '' + parseInt(theTime2 + '', unit) + '小时' + result;
    }
    if (theTime3 > empty) {
      result = '' + parseInt(theTime3 + '', unit) + '天' + result;
    }

    return result;
  }

  /**
   * 如果流程发生变化，则刷新数据
   */
  reloadPage (evt: any) {
    if (evt.data === 'reload' && evt.origin === window.location.origin) {
      this.onSearch();
    }
  }

  handleClickRow (record) {
    this.goPage(record);
  }

  get token () {
    return localStorage.getItem('token');
  }

  flowStatusPanelList: Array<{ label: string; code: EFlowStatusType }> = [];

  flowStatusTabValue = EFlowStatusType.All;

  init () {
    const flowStatusArray = deepClone(FlowStatusArray);
    for (const item of flowStatusArray) {
      this.flowStatusPanelList.push({
        label: item.text,
        code: item.code,
      });
    }
  }

  onChangeFlowStatusTab () {
    this.reload();
  }

  /**
   * 当前流程状态
   */
  get flowStatusType () {
    if (this.flowStatusTabValue === EFlowStatusType.All) {
      return null;
    } else {
      return this.flowStatusTabValue;
    }
  }
}
