

































import {
  Component, Prop, PropSync, Vue, Ref,
} from 'vue-property-decorator';
import { H3Modal, H3Button } from '@authine/awesome-ui';
import ApproveModal from './approve-modal.vue';
import { FlowCenterService } from '@WorkPlatform/api';
import * as Utils from '@WorkPlatform/common/utils';

@Component({
  name: 'agreeFlow',
  components: {
    AModal: H3Modal,
    AButton: H3Button,
    ApproveModal,
  },
})
export default class agreeFlow extends Vue {
  @PropSync('isVisible', { default: false }) visible!: boolean;

  @Ref() Approve!: any;

  @Prop({ default: () => ([]) }) flowList!: any;

  @Prop({ default: '' }) appCode!: any;

  @Prop({ default: '' }) schemaCode!: any;

  isLoading: boolean = false;

  /**
   * 确认回调
   */
  async handleOk () {
    this.isLoading = true;
    if (!this.appCode) {
      this.$message.warning('请选择待办应用');
      this.isLoading = false;
      this.$emit('update:isVisible', false);
      this.$emit('reload');
      return;
    }
    const submitInfo: any = this.Approve.submit();
    const params: any = {
      batch: {
        approval: submitInfo,
        workItemIds: this.flowList,
      },
      appCode: this.appCode,
      schemaCode: this.schemaCode,
    };
    FlowCenterService.batchSubmit(params).then(async (res) => {
      if (res.success) {
        if (res.data.failNum === 0) {
          await Utils.sleep(1000);
          this.$message.success('批量同意成功');
          this.$emit('update:isVisible', false);
          this.$emit('reload');
        } else {
          this.$emit('update:isVisible', false);
          this.$emit('hasFail', res.data);
        }
      }
    }).finally(() => {
      this.isLoading = false;
    });
  }

  handleCancel () {
    this.isLoading = false;
    this.$emit('update:isVisible', false);
  }
}
